( function ( _, $ ) {
    "use strict";

    var domain, rxAbsoluteInternalUrl,

        rxAbsoluteUrl = /^(?:https?:)?\/\//i,

        $body = $( "body" ),

        /**
         * Creates an object representing the external links on the page, or in a given scope.
         *
         * In order to make these links open in a new tab, or to add icons, you must call the update() method.
         *
         * @param {Object}  [options]
         * @param {jQuery}  [options.$scope=$("body")]          the scope to scan for external links, defaults to the body element
         * @param {string}  [options.exclude=""]                a selector which excludes elements, or their children, from being handled here
         * @param {boolean} [options.openInNewTab=true]         makes external links open in a new tab or window (target="_blank")
         * @param {boolean} [options.addIcons=false]            adds a class to external links (`.icon-external` by default). DOES NOT ADD THE ICON, must be done with CSS
         * @param {boolean} [options.noIconsForHeadings=false]  suppresses the icon class for links inside headings
         * @param {string}  [options.iconClass="icon-external"] defines the icon class
         *
         * @constructor
         */
        ExternalLinks = function ( options ) {
            _.bindAll( this, "update", "_scan", "_addTarget", "_addIcons" );

            options = _.extend( {
                $scope: $body,
                exclude: "",
                openInNewTab: true,
                addIcons: false,
                noIconsForHeadings: false,
                iconClass: "icon-external"
            }, options || {} );

            this.$scope = options.$scope;
            this.config = options;

            this._$cached = undefined;
        };


    _.extend( ExternalLinks.prototype, {

        update: function () {
            this
                ._scan()
                ._addIcons()
                ._addTarget();

            return this;
        },

        /**
         * Scans the scope for external links and adds rel="external" to them.
         *
         * Also caches the external links.
         *
         * @return {ExternalLinks}
         * @private
         */
        _scan: function () {
            var $excluded,

                $externalLinks = $( "a[href]", this.$scope )
                    .filter( function () {
                        return isExternalHref( this.getAttribute( "href" ) || "" )
                    } )
                    .attr( "rel", function ( i, currentValue ) {
                        var values = currentValue ? currentValue.split( " " ) : [];
                        if ( !_.contains( values, "external" ) ) values.push( "external" );
                        return values.join( " " );
                    } );

            if ( this.config.exclude ) {
                $excluded = $( this.config.exclude, this.$scope );
                $excluded = $excluded.add( $excluded.find( "a" ) );
                $externalLinks = $externalLinks.not( $excluded );
            }

            this._$cached = $externalLinks;
            return this;
        },

        /**
         * Adds target="_blank" to all links in the scope which are marked with rel="external".
         *
         * Respects the openInNewTab setting excluding links in headings. Relies on the cache. Requires _scan() to run
         * first, calls it if necessary.
         *
         * @return {ExternalLinks}
         * @private
         */
        _addTarget: function () {
            if ( !this.config.openInNewTab ) return this;
            if ( !this._$cached ) this._scan();

            this._$cached.attr( "target", "_blank" );

            return this;
        },

        /**
         * Adds a class to all links in the scope which are marked with rel="external" (`.icon-external` by default).
         * The icon itself must be applied by a matching CSS rule.
         *
         * Respects the setting excluding links in headings. Relies on the cache. Requires _scan() to run first, calls
         * it if necessary.
         *
         * @return {ExternalLinks}
         * @private
         */
        _addIcons: function () {
            var $addIcon, $headings;

            if ( !this.config.addIcons ) return this;
            if ( !this._$cached ) this._scan();

            $addIcon = this._$cached;

            if ( this.config.noIconsForHeadings ) {
                $addIcon = $addIcon.not( "h1 a, h2 a, h3 a, h4 a, h5 a, h6 a" );
            }

            $addIcon.addClass( this.config.iconClass );

            return this;
        },

    } );


    /**
     * Helpers
     */

    /**
     * Determines and returns the domain (hostname.tld) of the current page.
     *
     * Subdomains are excluded from the result. Ie, hostname "foo.bar.com" is returned as "bar.com". Caches the
     * result for better performance.
     *
     * @return {string}
     */
    function getDomain () {
        if ( !domain ) domain = location.host.match( /((?:[^.]+\.)?[^.:]+)(?::|$)/ )[1];
        return domain;
    }

    function getAbsoluteInternalUrlRegex () {
        if ( !rxAbsoluteInternalUrl ) rxAbsoluteInternalUrl = new RegExp( "^(?:https?:)?//(?:[^.]+\\.)?" + escapeRegExp( getDomain() ) + "(?:[:/]|$)", "i" );
        return rxAbsoluteInternalUrl;
    }

    function isExternalHref ( href ) {
        return !!href && rxAbsoluteUrl.test( href ) && !( getAbsoluteInternalUrlRegex().test( href ) );
    }

    /**
     * Escapes a string so that it can become part of a regex, without any of its characters accidentally being
     * interpreted as a special regex character.
     *
     * Taken from MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
     *
     * @param  {string} input
     * @return {string}
     */
    function escapeRegExp( input ) {
        return input.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ); // $& means the whole matched string
    }


    /**
     * Export global objects
     */
    window.ExternalLinks = ExternalLinks;

} )( _, jQuery );